import React from "react"
import { Link } from "gatsby"
import * as mainNavStyles from "./main-nav.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Image from "gatsby-image"
import clsx from "clsx"
import { faBars } from "@fortawesome/free-solid-svg-icons"

export default function MainNav({ logoImageData }) {
  return (
    <div className={mainNavStyles.root}>
      <input type="checkbox" />
      <div className={clsx(mainNavStyles.menuBtn)}>
        <FontAwesomeIcon icon={faBars} size="2x" />
      </div>
      <nav className={mainNavStyles.mainNav}>
        <Link to="/">
          <Image
            fluid={logoImageData}
            className={mainNavStyles.logo}
            alt="Quartz Logo"
          />
        </Link>

        <ul className={mainNavStyles.mainMenu}>
          <li>
            <Link to="/washing-machines">Auto Washing Machines</Link>
          </li>
          <li>
            <Link to="/refrigerators">Refrigerators</Link>
          </li>
          <li>
            <Link to="/kitchen-appliances">Kitchen Appliances</Link>
          </li>
          <li>
            <Link to="/semi-auto">Semi-Automatic Washing Machines</Link>
          </li>
          <li>
            <Link to="/downloads">Downloads</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
