import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../utils/fontawesome"
import "./layout.css"
import Container from "./container"
import MainNav from "./main-nav"
import Footer from "./footer"

export default function Layout({ children }) {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const logoImageData = data.logo.childImageSharp.fluid
  return (
    <Fragment>
      <Container>
        <MainNav logoImageData={logoImageData} />
        {children}
      </Container>
      <Footer />
    </Fragment>
  )
}
