import React from "react"
import * as footerStyles from "./footer.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className={footerStyles.footer}>
      <div className={footerStyles.footerInner}>
        <div>
          <FontAwesomeIcon icon={faGlobe} size="2x" /> English (United States)
        </div>
        <div>
          <Link to="/vision" className={footerStyles.link}>
            Corporate Vision & Policy
          </Link>
        </div>
        <ul>
          <li>&copy; 2023 Quartz. All Rights Reserved</li>
        </ul>
      </div>
    </footer>
  )
}
